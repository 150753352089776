import { Inject, Injectable, PLATFORM_ID, inject } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
// import { HttpClient } from "@angular/common/http";
// import { HttpService } from "./http.service";

@Injectable({
    providedIn: "root"
})

export class UtilitySerice {
    

    // private http = inject(HttpClient)
    // private $http = inject(HttpService)
    constructor(@Inject(PLATFORM_ID) private platformId: any) { }

    getAuthToken() {
        if (isPlatformBrowser(this.platformId)) {
            return localStorage.getItem("survey_admin_token");
        }
        // On the server, return some default value, like 1 or null
        return null;
    }

    clearStorage() {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem("survey_admin_token");
        }
    }

    trim(data: any) {
        console.log("yes i am in utility service in trim function")
        for (const item in data) {
            if (typeof data[item] === "string") {
                data[item] = data[item].trim();
            }
        }
        return data;
    }

    setLocalStorage(key: string, value: string) {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(key, value);
        }
    }

    getLocalStorage(key: string): string {
        try {
            if (isPlatformBrowser(this.platformId)) {
                const value = localStorage.getItem(key);
                return value !== null ? value : "";
            }
        } catch (error) {
            console.error('Error accessing localStorage:', error);
        }
        return "";
    }
    

    // getpermissions(role:any)
    // {
    //     return this.$http.post("permissionList", role)
    // }
}

