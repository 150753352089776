import { Component, Inject, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterModule } from '@angular/router';
// import { Router } from '@angular/router';
import { ConfirmLogoutDialogComponent } from '../../common/confirm.component';
import { LOGIN } from '../../constant/absolute-routes';
import { MatListModule } from '@angular/material/list';
import { CommonModule } from '@angular/common';
import { DataTransferService } from '../../shared/services/data-transfer.service';
import { UtilitySerice } from '../../shared/services/utility.service';
@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [RouterLink, MatIcon, MatListModule, RouterModule, CommonModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit {
  isActive = false;
  private router = inject(Router)
  _dataTransferService = inject(DataTransferService)
  role :any;
  permission:any;
  // private $route = Inject(ActivatedRoute);

  name:string = ''


  ngOnInit(): void {
    this.role  = localStorage.getItem('survey_admin_role');
    this.permissionList({role:this.role})
    // console.log(this.router.snapshot.url , "url odf minjkkkkkkkkkkkkk") ;
  }

  constructor(private dialog: MatDialog, private _utilityService: UtilitySerice) {
    this.name = this._utilityService.getLocalStorage('survey_admin_name');

  }
  detectRoute() {
    const currentUrl = this.router.url
    this.isActive = true;
    if (currentUrl.includes('/checkInDetail/')) {
      return;
    }
    this.router.navigate(['/checkInList'])
  }

  logout() {
    const dialogRef = this.dialog.open(ConfirmLogoutDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        localStorage.removeItem('survey_admin_token');
        localStorage.removeItem('survey_admin_name');
        localStorage.removeItem('survey_admin_id');
        this.router.navigate([LOGIN]);
      }
    });
  }

  isRouteActive(paths: string[]): boolean {
    return paths.some(path => this.router.url.startsWith(path));
  }

 

  permissionList(role:any)
  {
    // console.log(role,"yes within role")
    let res: any = this._dataTransferService.getpermissions(role).subscribe((res: any) => {
      // console.log(res, "any check data")
      this.permission = res.data ? res.data.permission:undefined;
      // console.log(this.permission, "check permissions data ")


    })
  }
  






}
