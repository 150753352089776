import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from './http.service';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { get } from 'http';
import { PROFILE } from '../../constant/urls';


@Injectable({
    providedIn: 'root'
})
export class DataTransferService {
    profileDetail = new BehaviorSubject<any>(null);
    profileSubject$ = new BehaviorSubject<any>(null);
    profileData: any;
    profileInfo: any;

    constructor(
        private _http: HttpService
    ) {
    }

    async getprofileData(refresh = false) {
        // try {
        //   if (this.profileInfo && !refresh) {
        //     return this.profileInfo;
        //   }

        //   const resp : any = await this._http.get(PROFILE).toPromise();
        //   this.profileSubject$.next(resp.data);
        //   this.profileInfo = resp;
        // //   this.storageService.setMenuData(resp.data.roles);
        //   // console.log(resp);
        //   return this.profileInfo;
        // } catch (error) {}
    }

    // getProfileDetail(refresh=false) {

    //     // console.log('profile--cal test',com);

    //     return new Observable((observer) => {
    //         if (this.profileData && !refresh) {
    //             observer.next(this.profileData);
    //         } else {
    //             this._http.get(PROFILE).subscribe(
    //                 response => {
    //                     if (response['statusCode'] === 200) {
    //                         this.profileData = response;
    //                         console.log(response)
    //                         observer.next(response);
    //                     } else {
    //                         observer.next(null);
    //                     }
    //                 }, error => {
    //                     observer.next(null);
    //                 }
    //             );
    //         }
    //     });
    // }

    getpermissions(role: any) {
        // console.log(role,typeof role,"check role")
        return this._http.get("permissionList", role)
    }
}
