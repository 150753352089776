import { Routes } from "@angular/router"
// import { Router } from 'express';

export const assignmentRouter: Routes = [
  {
    path: '',
    loadComponent:() => import ('./pages/assignment-list/assignment-list.component').then(m=>m.AssignmentListComponent)
  
  },
  {
    path: '',
    pathMatch: "full",
    redirectTo: 'assignment'
  },

  {
    path : 'addAssignment',
    loadComponent:() => import('./pages/add-assignment/add-assignment.component').then(m=>m.AddAssignmentComponent)
    
   },
   {
    path : 'addAssignment/:id',
    loadComponent:() => import('./pages/add-assignment/add-assignment.component').then(m=>m.AddAssignmentComponent)
    
   },
   {
    path : 'details/:id',
    loadComponent:() => import ('./pages/assignment-details/assignment-details.component').then(m=>m.AssignmentDetailsComponent)
    
    
   },




]