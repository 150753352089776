import { Routes } from "@angular/router"
// import { Router } from 'express';

export const approvalsRouter: Routes = [
    {
        path: '',
        loadComponent :() => import ('./pages/accept-approvals/accept-approvals.component').then(m => m.AcceptApprovalsComponent)
        // loadComponent: () => import('./pages/assignment-list/assignment-list.component').then(m => m.AssignmentListComponent)

    },
    {
        path: '',
        pathMatch: "full",
        redirectTo: 'approvals'
    },



]