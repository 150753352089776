import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LayoutComponent } from './components/layout/layout.component';
import { LoaderComponent } from './common/loader/loader.component';
import { v4 as uuidv4 } from 'uuid';
import { UtilitySerice } from './shared/services/utility.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet,LayoutComponent , LoaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{
  title = 'sales-admin-panel';
  _utilityService = inject(UtilitySerice)

  ngOnInit(): void {
    this._utilityService.setLocalStorage('deviceId', uuidv4());
  }
}
