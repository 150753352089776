export const vechileType = [
    { value: 1, viewValue: 'BIKE' },
    { value: 2, viewValue: 'CYCLE' },
    { value: 3, viewValue: `CAR` },

];

export const EMPLOYEE_ROLES = [
    { value: -1, viewValue: 'ADMIN' },
    { value: 0, viewValue: 'RSM' },
    { value: 1, viewValue: `ASM` },
    { value: 2, viewValue: `SO` },
    { value: 3, viewValue: `ISR` },
];

export const EMPLOYEE_ROLE = {
    ADMIN: -1,
    RSM: 0,
    ASM: 1,
    SO: 2,
    ISR: 3
}

export const DAYS =[
    {value :1, viewValue :"SUNDAY"},
    {value :2, viewValue :"MONDAY"},
    {value :3, viewValue :"TUESDAY"},
    {value :4, viewValue :"WEDNESDAY"},
    {value :5, viewValue :"THURSDAY"},
    {value :6, viewValue:"FRIDAY"},
    {value:7, viewValue:"SATURDAY"}

]

export const  ASSIGN_BY =[
    {value :1, viewValue :"DAY WISE"},
    {value : 2, viewValue :"DATE WISE"}
]
	



export const EMPLOYEE_ROLES_PERMISSION = {
    SUPER_ADMIN: -1
}



export const DOCUMENT_TYPE = [
    { value: "adharCard", viewValue: 'Adhar Card' },
    { value: "panCard", viewValue: 'Pan Card' },
    { value: "drivingLicence", viewValue: `Driving Licence` },
    { value: "empImage", viewValue: 'Employee Image' },


];

export enum Module {
    Employee = 'employee',
    CheckIn = "checkIn",
    Store = "store",
    BlockRoute = "blockRoute",
    RouteMaster = "routeMaster",
    documentMaster = "masterDocuments",
    assignments ="assignments",
    settings ="settings",
    reports ="reports",
    approvals ="approvals"
}


export const Subadmin_permission = [
    { Manage_Type: Module.Employee, Module_Name: 'Employee', none: 1, view: 2, modify: 3, path: '' },
    { Manage_Type: Module.CheckIn, Module_Name: 'check In', none: 1, view: 2, modify: 3, path: '' },
    { Manage_Type: Module.BlockRoute, Module_Name: 'block Route', none: 1, view: 2, modify: 3, path: '' },
    { Manage_Type: Module.RouteMaster, Module_Name: 'Route Master', none: 1, view: 2, modify: 3, path: '' },
    { Manage_Type: Module.documentMaster, Module_Name: 'Document Master', none: 1, view: 2, modify: 3, path: '' },
    { Manage_Type: Module.settings, Module_Name: 'Settings', none: 1, view: 2, modify: 3, path: '' },
    { Manage_Type: Module.reports, Module_Name: 'Reports', none: 1, view: 2, modify: 3, path: '' },
    { Manage_Type: Module.assignments, Module_Name: 'Assignment', none: 1, view: 2, modify: 3, path: '' },
    { Manage_Type: Module.approvals, Module_Name: 'Approvals', none: 1, view: 2, modify: 3, path: '' },
]



export enum Action {
    NONE = 1,
    VIEW = 2,
    MODIFY = 3,
    DOWNLOAD = 4,
    DELETE = 5,
}





