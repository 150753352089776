import { Routes } from '@angular/router';
import { clusterRoutes } from './components/cluster/cluster.routes';
import { canActivateTeam } from './guards/home.guard';
import { userRouter } from './components/user/user.routing';
import { storeRoutes } from './components/store/store.routes';
import { RoutesComponent } from './components/routes/routes.component';
import { RouterRoutes } from './components/routes/routes.route';
import { reportsRoutes } from './components/reports/reports.routes';
import { documentRouter } from './components/master-document/master-document.routing';
import { rightsRouter } from './components/role-rights/add-rights.routing';
import { roleGuard } from './guards/role.guard';
import { assignmentRouter } from './components/assignment/assignment.route';
import { approvalsRouter } from './components/approvals/approvals.route';

export const routes: Routes = [
    {
        path: '',
        loadComponent: () => import('./components/layout/layout.component').then((c) => c.LayoutComponent),
        canActivate: [canActivateTeam],
        children: [
            // {
            //     path : 'dashBoard',
            //     loadComponent : () => import('./components/dashboard/dashboard.component').then((c)=>c.DashboardComponent)
            // },
            {
                path: 'user',
                loadComponent: () => import('./components/user/user.component').then((c) => c.UserComponent),
                children: userRouter,
                // canActivate: [roleGuard],
                // data:{
                //     title:"employee"
                // }
            },
            {
                path: 'blockROute',
                loadComponent: () => import('./components/cluster/cluster.component').then((c) => c.ClusterComponent),
                children: clusterRoutes,
                canActivate: [roleGuard],
                data: {
                    title: "blockRoute"
                }
                // canActivate: [roleGuard],


            },

            {
                path: "",
                redirectTo: 'user',
                pathMatch: "full",
                data: {
                    title: "employee"
                }
            },

            {
                path: 'checkInList',
                loadComponent: () => import('./components/user/pages/check-in-details/check-in-details.component').then((c) => c.CheckInDetailsComponent),
                canActivate: [roleGuard],
                data: {
                    title: "checkIn"
                }
            },
            {
                path: 'checkInList/:id',
                loadComponent: () => import('./components/user/pages/check-in-details/check-in-details.component').then((c) => c.CheckInDetailsComponent),
                canActivate: [roleGuard],
                data: {
                    title: "checkIn"
                }
            },
            {
                path: 'store',
                loadComponent: () => import('./components/store/store.component').then(m => m.StoreComponent),
                children: storeRoutes,
                canActivate: [roleGuard],
                data: {
                    title: "store"
                }
            },
            {
                path: 'route',
                loadComponent: () => import('./components/routes/routes.component').then(m => m.RoutesComponent),
                children: RouterRoutes,
                canActivate: [roleGuard],
                data: {
                    title: "blockRoute"
                }
            },
            {
                path: 'settings',
                loadComponent: () => import('./components/settings/settings.component').then(m => m.SettingsComponent),
                canActivate: [roleGuard],
                data: {
                    title: "settings"
                }
            },
            {
                path: 'reports',
                loadComponent: () => import('./components/reports/reports.component').then(m => m.ReportsComponent),
                children : reportsRoutes
            },
            {
                path: 'documents',
                loadComponent: () => import('./components/master-document/master-document.component').then(m => m.MasterDocumentComponent),
                children: documentRouter,
                canActivate: [roleGuard],
                data: {
                    title: "masterDocuments"
                }

            },

            {
                path: 'rights',
                loadComponent: () => import('./components/role-rights/role-rights.component').then(m => m.RoleRightsComponent),
                children: rightsRouter,
                // data:{
                //     title:"employee"
                // }


            },
            {
                path:'assignment',
                loadComponent:() =>import('./components/assignment/assignment.component').then(m=>m.AssignmentComponent),
                children:assignmentRouter,
                canActivate: [roleGuard],
                data:{
                    title:"assignments"
                }
            },
            {
                path:'approvals',
                loadComponent:() => import ('./components/approvals/approvals.component').then(m=>m.ApprovalsComponent),
                children:approvalsRouter,
                canActivate: [roleGuard],
                data:{
                    title:"approvals"
                }
            
            }


        ]
    },
    {
        path: 'login',
        loadComponent: () => import('./components/login/login.component').then((c) => c.LoginComponent)
    },
    // {
    //     path:'dasboard',
    //     loadComponent:()=> import('./components/dashboard/dashboard.component').then((c)=>c.DashboardComponent)
    // },


];
