import { Routes } from "@angular/router";


export const  reportsRoutes : Routes = [
    
    {
        path : 'wrapper',
        loadComponent : () => import("./pages/router-wrapper/router-wrapper.component").then(m => m.RouterWrapperComponent),
    },
    {
        path : '',
        pathMatch : 'full',
        redirectTo : 'wrapper',
    },
    {
        path : 'attendance',
        loadComponent : () => import("./pages/attendance-report/attendance-report.component").then(m => m.AttendanceReportComponent)
    },
    {
        path : 'tracking',
        loadComponent : () => import("./pages/executive-tracking/executive-tracking.component").then(m => m.ExecutiveTrackingComponent)
    },
    {
        path : 'visit',
        loadComponent : () => import("./pages/executive-visit/executive-visit.component").then(m => m.ExecutiveVisitComponent)
    },
    
   
]