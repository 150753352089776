import { Injectable, inject } from "@angular/core";
import { CanActivateFn  , ActivatedRouteSnapshot , RouterStateSnapshot , Router} from "@angular/router";
import { Observable } from 'rxjs';
import { UtilitySerice } from "../shared/services/utility.service";
import { DataTransferService } from "../shared/services/data-transfer.service";
import { LOGIN } from "../constant/absolute-routes";


@Injectable({
    providedIn : 'root'
})
class PermissionsService {

    router = inject(Router)
    _utilityService = inject(UtilitySerice)
    _dataTransferService = inject(DataTransferService)
    constructor(){

    }
  canActivate(route : ActivatedRouteSnapshot , next : RouterStateSnapshot) {

   
    // return true
    if (this._utilityService.getAuthToken()) {
        
        return this.tokenVerify();
    }
  
   
    return this.navigate();
    
  


  }

  async tokenVerify() {
    await this._dataTransferService.getprofileData(true);
    return true;
  }
  
  navigate() {
    this._utilityService.clearStorage();
    this.router.navigate([LOGIN]);
    return false;
  }

}

export const canActivateTeam: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(PermissionsService).canActivate(route , state);
};