import { HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable, EMPTY, catchError, tap } from "rxjs";
import { UtilitySerice } from "../shared/services/utility.service";
import { HttpEventType } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar"; // Add MatSnackBar for showing error messages

import { Router } from '@angular/router';
import { LoaderService } from "../common/loaderService/loader.service";
import { LOGIN } from "../constant/absolute-routes";
@Injectable({
    providedIn: "root"
})
export class TokenInterceptor implements HttpInterceptor {
    router = inject(Router)

    $utiltiy = inject(UtilitySerice);
    private snackBar = inject(MatSnackBar); // Inject MatSnackBar
    private loaderService = inject(LoaderService)
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        this.loaderService.show();
        let headers: any = {};
        const token = this.$utiltiy.getAuthToken();
        if (token) {
            headers["Authorization"] = "Bearer " + token;
        }
        
        headers["api_key"] = "123456";
        headers["platform"] = "1";
        headers["timezone"] = "480";
        headers["deviceId"] = localStorage.getItem("deviceId")
        const reqWithHeader = request.clone({
            setHeaders: headers
        });

        return next.handle(reqWithHeader).pipe(
            tap((event: any) => {
                
                if (event.type === HttpEventType.Response) {
                       this.loaderService.hide();
                }
            }),
            catchError((error: HttpErrorResponse) => {
                this.loaderService.hide();

                let errorMessage = 'An unknown error occurred.';
                if (error.status === 401) {
                    errorMessage = 'Unauthorized access. Please log in.';
                    this.router.navigate([LOGIN]);
                } else if (error.status === 500) {
                    errorMessage = 'Server error. Please try again later.';
                } else if (error.error?.message) {
                    errorMessage = error.error.message;
                }

                this.snackBar.open(errorMessage, 'Close', {
                    duration: 3000, 
                    panelClass: ['error-snackbar']
                });

                return EMPTY; 
            })
            
        );

        
    }
}
